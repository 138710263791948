<template>
  <b-modal ref="modal" size="md" centered scrollable visible no-close-on-esc :id="modalId">
    <template #modal-header>
      <div class="tlw-w-full tlw-px-2">
        <div class="tlw-relative text-center tlw-mb-0">
          <h3 class="tlw-font-bold tlw-text-center tlw-text-xl tlw-mb-1">
            {{ $t('myspark.student-area.recommended-products-section.modal.title') }}
          </h3>
          <p class="tlw-text-sm tlw-mb-0">
            {{ $t('myspark.student-area.recommended-products-section.modal.subtitle') }}
          </p>

          <button type="button" @click="$emit('closeModal')" title="fechar">
            <hs-icon icon="times" size="18" class="tlw-absolute tlw-right-0 tlw-top-0" />
          </button>
        </div>

        <hs-group label-for="search" class="mb-0">
          <hs-input
            id="search"
            v-model.trim="search"
            type="text"
            data-testid="search-filter-input"
            class="tlw-border-0"
            icon="search"
            :placeholder="$t('myspark.student-area.recommended-products-section.modal.search-placeholder')"
            :nestedAction="() => {}"
          />
        </hs-group>
      </div>
    </template>

    <template>
      <div class="products">
        <div class="products__list" ref="productsList">
          <div v-if="loading === 'loading' || loading === 'searching'" class="tlw-text-center tlw-mt-8">
            <b-spinner variant="gray" small class="tlw-mr-2"></b-spinner>
            {{ $t('general.loading') }}...
          </div>

          <template v-else>
            <div v-if="!products.length">
              {{ $t('myspark.student-area.recommended-products-section.modal.not-found') }}
            </div>

            <label
              class="product-item tlw-flex tlw-items-center tlw-border tlw-rounded tlw-p-3 tlw-mb-2"
              v-for="product in products"
              :key="product.id"
              :for="`product-${product.id}`"
            >
              <hs-checkbox
                v-model="product.recommended"
                :id="`product-${product.id}`"
                @input="setCheckedProduct($event)"
              />

              <div class="product-info tlw-flex-1 tlw-flex tlw-items-center tlw-justify-between tlw-pr-3">
                <span class="tlw-text-sm tlw-pr-4">{{ product.name }}</span>
              </div>
            </label>

            <infinite-loading spinner="waveDots" @infinite="infiniteScrollHandler" v-if="products.length">
              <div slot="no-more">{{ $t('general.no-more-results') }}</div>
              <div slot="no-results">{{ $t('general.no-more-results') }}</div>
            </infinite-loading>
          </template>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="d-flex w-100">
        <div class="d-flex align-items-center tlw-border tlw-rounded p-2">
          <hs-checkbox v-model="checkAll" id="product-check-all" @change="setCheckedAllProducts($event)" />
          <span>{{ $t('myspark.student-area.recommended-products-section.modal.check-all') }}</span>
        </div>
        <div class="tlw-flex tlw-flex-1 tlw-justify-end">
          <MButton
            type="button"
            variant="primary-outline"
            :label="$t('general.cancel')"
            @click="$emit('closeModal')"
            class="tlw-mr-3"
          />

          <MButton
            data-testid="btn-add-product"
            type="button"
            variant="primary"
            :label="$t('general.save')"
            :loading="isUpdating"
            :disabled="isUpdating"
            @click="updateRecommended"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import productService from '@/sparkmembers/services/product';
import ToastHelper from '@/shared/helpers/toast';
import debounce from 'lodash.debounce';
import MButton from '@/shared/components/MButton.vue';
import InfiniteLoading from 'vue-infinite-loading';
import _ from 'lodash';

export default {
  props: {
    modalId: {
      required: true,
      type: String,
    },
  },
  components: {
    MButton,
    InfiniteLoading,
  },
  data() {
    return {
      checkAll: null,
      loading: 'loading',
      page: 1,
      perPage: 10,
      totalPages: 0,
      totalProducts: 0,
      products: [],
      search: '',
    };
  },
  created() {
    this.searchProducts = debounce(this.searchProducts, 600);
  },
  async mounted() {
    await this.getProducts();
  },
  watch: {
    async search() {
      this.loading = 'searching';
      this.products = [];
      this.page = 1;
      await this.searchProducts(this.search);
    },
  },
  computed: {
    isUpdating() {
      return this.loading === 'updating';
    },
  },
  methods: {
    searchProducts(search) {
      return this.getProducts(search);
    },
    async getProducts(searchByTitle = '') {
      try {
        const query = [
          { key: 'page', value: this.page },
          { key: 'per_page', value: this.perPage },
          { key: 'recommended', value: true },
          { key: 'sort', value: 'title' },
        ];

        searchByTitle !== '' && query.push({ key: 'title', value: `${searchByTitle}` });

        const { school_products, total_pages, total_count } = await productService.getAll(query);

        this.totalPages = total_pages;
        this.totalProducts = total_count;

        const formatedProducts = this.formatProducts(school_products);
        this.products = _.unionBy(this.products, formatedProducts, 'id');
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('myspark.student-area.recommended-products-section.modal.toast.error'));
      } finally {
        this.loading = 'done';
      }
    },
    async infiniteScrollHandler($state) {
      if (this.page < this.totalPages) {
        this.page += 1;
        await this.getProducts(this.search);
        $state?.loaded();
      } else {
        $state?.complete();
      }
    },
    async updateRecommended() {
      let payload = {
        school_products: this.products.map(product => ({
          id: product.id,
          recommended: product.recommended,
        })),
      };
      if (this.checkAll != null) payload = { all: this.checkAll, school_products: payload.school_products };
      this.$emit('updateRecommended', payload);
    },
    setCheckedProduct(value) {
      if (!value) {
        this.checkAll = false;
      }
    },
    formatProducts(products) {
      return products.reduce((acc, product) => {
        if (product.published) {
          acc.push({
            id: product.id,
            name: product.title,
            recommended: this.checkAll == null ? product.recommended : this.checkAll,
          });
        }
        return acc;
      }, []);
    },
    setCheckedAllProducts(value) {
      this.products.forEach(product => (product.recommended = value));
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .form-control {
  &:focus {
    border-color: $purple-dark;
  }

  &:focus-visible {
    outline: auto $purple-dark !important;
  }
}
/deep/ .modal {
  .modal-dialog {
    max-width: 560px;
    .modal-header,
    .modal-body,
    .modal-footer {
      padding: 2rem;
    }
    .custom-control-input:checked ~ .custom-control-label:before {
      border-color: $purple-dark;
      background-color: $purple-dark;
    }
    .custom-checkbox:hover .custom-control-input:not(:disabled) ~ .custom-control-label:before {
      border: 2px solid $purple-dark;
    }
  }
}
</style>
